import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import { Box } from 'grommet'
import WPGBlocks from 'react-gutenberg'
import GetCustomBlock from '../blocks'
import CustomFieldBlocks from '../components/CustomBlocks'
import '../scss/@wordpress/block-library/build-style/style.css'
import '../scss/@wordpress/block-library/build-style/theme.css'

export const PageTemplate = ({ title, content, blocks, contentBlocks }) => {
  return (
    <Box>
      <Box pad={{ horizontal: 'large' }}>
        <WPGBlocks
          blocks={blocks}
          mapToBlock={name => GetCustomBlock(name, content)}
        />
      </Box>
      <CustomFieldBlocks blocks={contentBlocks} />
    </Box>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wpPage: page } = data
  return (
    <Layout>
      <PageTemplate
        title={page.title}
        content={page.content}
        blocks={page.blocks}
        contentBlocks={page.contentBlocks}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      uri
      blocks {
        attributesJSON
        blockName: name
        innerHTML: saveContent
        dynamicContent
        innerBlocks {
          attributesJSON
          blockName: name
          innerHTML: saveContent
          dynamicContent
          # attrs {
          #   blockId
          #   blockUniqueClass
          #   custom_name
          # }
          innerBlocks {
            attributesJSON
            blockName: name
            innerHTML: saveContent
            dynamicContent
            # attrs {
            #   blockId
            #   blockUniqueClass
            #   custom_name_in_column
            # }
          }
        }
      }
      contentBlocks {
        layoutBlocks {
          ... on WpPage_Contentblocks_LayoutBlocks_Chain {
            fieldGroupName
            title
            section {
              body
              fieldGroupName
              title
              icon {
                localFile {
                  publicURL
                }
              }
            }
            type
            ctaButton {
              url
              title
              target
            }
          }
          ... on WpPage_Contentblocks_LayoutBlocks_TemporaryBlock {
            fieldGroupName
            tempContent
          }
          ... on WpPage_Contentblocks_LayoutBlocks_SwooshText {
            fieldGroupName
            text
            ctaButton {
              url
              title
              target
            }
          }
          ... on WpPage_Contentblocks_LayoutBlocks_MultipleImageBlock {
            fieldGroupName
            subtitle
            withText
            title
            images {
              fieldGroupName
              caption
              image {
                localFile {
                  childImageSharp {
                    # Try editing the "maxWidth" value to generate resized images.
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Contentblocks_LayoutBlocks_CenteredTextBlock {
            body
            fieldGroupName
            greenBackground
            subtitle
            title
            buttonLink {
              url
              title
              target
            }
            underlineTitle
          }
          ... on WpPage_Contentblocks_LayoutBlocks_LeftRightWithImage {
            align
            body
            fieldGroupName
            swoosh
            buttonLink {
              target
              title
              url
            }
            title
            subtitle
            whiteBox
            image {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WpPage_Contentblocks_LayoutBlocks_SwooshCarousel {
            blockTitle
            fieldGroupName
            button {
              target
              title
              url
            }
            panel {
              body
              buttonLink {
                target
                title
                url
              }
              fieldGroupName
              image {
                localFile {
                  childImageSharp {
                    # Try editing the "maxWidth" value to generate resized images.
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Contentblocks_LayoutBlocks_Carousel {
            fieldGroupName
            title
            panel {
              body
              fieldGroupName
              subtitle
              title
              buttonLink {
                target
                title
                url
              }
            }
          }

          ... on WpPage_Contentblocks_LayoutBlocks_SwooshText {
            fieldGroupName
            text
          }
          ... on WpPage_Contentblocks_LayoutBlocks_LeftRightGreenImage {
            align
            body
            title
            subtitle
            fieldGroupName
            whiteBox
            backgroundImage
            buttonLink {
              target
              title
              url
            }
            image {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WpPage_Contentblocks_LayoutBlocks_TeamProfiles {
            body
            fieldGroupName
            subtitle
            title
            image {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            # total
          }
        }
        isFunctional
        infoPoints {
          content
          fieldGroupName
          title
          name
        }
        fieldGroupName
        editableRegions {
          name
          fieldGroupName
          content
        }
      }
    }
  }
`
